import Splide from '@splidejs/splide';

const splideOptions = {
  rewind: false,
  gap   : '2rem',
  // TODO.. ADD EXTRA SMALL CARD PER PAGE
  perPage: 1,    
  speed: 800,
}

const mobileGridCarouselInit = () => {
  const carousels = Array.from(document.querySelectorAll('.mobile-grid-slider'))
  carousels.forEach(item => {
    const slider = new Splide( item, splideOptions );
    slider.mount();
  })
}

export default mobileGridCarouselInit