// Listen for focus/blur on search input
const searchFocusListener = () => {
  const search = document.getElementById('search');
  const search__icon = document.querySelector('.search__icon');
  // const search__close = document.querySelector('.search__close');
  if (!search) return;
  search.addEventListener('focus', (e) => {
    search.classList.add('focus');
    search__icon.classList.add('focus');
  });
  search.addEventListener('blur', (e) => {
    search.classList.remove('focus');
    search__icon.classList.remove('focus');
  });
};

// Listen for change on search input
const searchChangeListener = () => {
  // const search = document.getElementById('search');
  // const search__close = document.querySelector('.search__close');
  // if (!search) return;
  // if (search.value === '') {
  //   search__close.classList.add('hidden');
  // } else {
  //   search__close.classList.remove('hidden');
  // }
  // search.addEventListener('input', (e) => {
  //   if (search.value === '') {
  //     search__close.classList.add('hidden');
  //   } else {
  //     search__close.classList.remove('hidden');
  //   }
  // });
};

// Listen for mouseenter/mouseeleave on search input
const searchEnterLeaveListener = () => {
  const search = document.getElementById('search');
  const search__icon = document.querySelector('.search__icon');
  if (!search) return;
  search.addEventListener('mouseenter', (e) => {
    e.currentTarget.classList.add('hover');
    search__icon.classList.add('hover');
  });

  search.addEventListener('mouseleave', (e) => {
    e.currentTarget.classList.remove('hover');
    search__icon.classList.remove('hover');
  });
};

const closeSearch = () => {
  // Close search
  const closeSearchBtn = document.querySelector('.close-search');

  if (closeSearchBtn) {
    closeSearchBtn.addEventListener('click', (e) => {
      e.preventDefault();

      let readValue;

      readValue = sessionStorage['prevpath'];

      if (!readValue) {
        readValue = '/';
      }

      location.href = `${readValue}`;
    });
  }
};

const initSearch = () => {
  searchFocusListener();
  searchChangeListener();
  searchEnterLeaveListener();
  closeSearch();
};

export default initSearch;
