const contactFormInit = () => {
  const section = document.querySelector('[data-module="contact-form"]');

  if (section) {
    let selects = section.querySelectorAll('.wpcf7-select');

    [...selects].forEach((select) => {
      let label = select.closest('label');
      label.classList.add('!hidden');
      let span = label.querySelector('span:first-child');
      let parent = label.parentNode;

      let labelText = span.innerText;

      let el = document.createElement('div');
      el.classList.add('dropdown');

      let labelEl = document.createElement('span');
      labelEl.classList.add('dropdown__label');
      labelEl.innerText = labelText;

      let dropdownEl = document.createElement('div');
      dropdownEl.classList.add('dropdown__list');

      let options = select.querySelectorAll('option');

      [...options].forEach((option) => {
        let optionEl = document.createElement('span');
        optionEl.classList.add('dropdown__item');
        optionEl.innerText = option.innerText;
        optionEl.value = option.value;

        optionEl.addEventListener('click', () => {
          labelEl.innerText = optionEl.innerText;
          select.value = optionEl.value;
          el.classList.remove('active');
          labelEl.classList.remove('active');

          console.log(select.value !== '');

          if (select.value !== '') {
            labelEl.classList.add('has-value');
          } else {
            labelEl.classList.remove('has-value');
          }
        });

        dropdownEl.appendChild(optionEl);
      });

      el.appendChild(labelEl);
      el.appendChild(dropdownEl);

      parent.appendChild(el);

      labelEl.addEventListener('click', () => {
        el.classList.toggle('active');
        labelEl.classList.toggle('active');
      });
    });
  }

  // Get all input elements
  const inputs = document.querySelectorAll('.wpcf7-text');

  // Loop through each input
  inputs.forEach((input) => {
    input.addEventListener('change', function () {
      if (this.value) {
        this.classList.add('has-value');
      } else {
        this.classList.remove('has-value');
      }
    });
  });
};

export default contactFormInit;
