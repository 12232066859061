const handleBtnClick = (e) => {
  const el = e.currentTarget
  const currentlyActiveAccordionItemHeader = el.parentElement.querySelector(
    ".accordion-item-header.active"
  );
  if (
    currentlyActiveAccordionItemHeader &&
    currentlyActiveAccordionItemHeader !== el
  ) {
    currentlyActiveAccordionItemHeader.classList.toggle("active");
    currentlyActiveAccordionItemHeader.classList.toggle("!text-primary");
    currentlyActiveAccordionItemHeader.classList.toggle("!bg-grey-100")
    currentlyActiveAccordionItemHeader.querySelector('.accordion-item__arrow').classList.toggle("rotate-180")
    currentlyActiveAccordionItemHeader.nextElementSibling.classList.toggle("!bg-grey-100")
    currentlyActiveAccordionItemHeader.nextElementSibling.style.maxHeight = 0;
  }
  el.classList.toggle("active");
  el.classList.toggle("!text-primary");
  el.classList.toggle("!bg-grey-100")
  el.querySelector('.accordion-item__arrow').classList.toggle("rotate-180")
  el.nextElementSibling.classList.toggle("!bg-grey-100")
  const accordionItemBody = el.nextElementSibling;
  if (el.classList.contains("active")) {
    accordionItemBody.style.maxHeight = accordionItemBody.scrollHeight + "px";
  } else {
    accordionItemBody.style.maxHeight = 0;
  }
}

const accordionInit = () => {
  const accordionsInstances = Array.from(document.querySelectorAll('.accordion'))
  accordionsInstances.forEach(instance => {
    const accordionItems = Array.from(instance.querySelectorAll('.accordion-item-header'))
    accordionItems.forEach(item => item.addEventListener('click', handleBtnClick))
  })
}

export default accordionInit