import Sticky from 'sticky-js';

const stickyInit = () => {
  const stickyEls = Array.from(document.querySelectorAll('.sticky-area'));
  stickyEls.forEach((item) => {
    item.parentElement.style.width = '100%';
  });
  var sticky = new Sticky('.sticky-area');
};

export default stickyInit;
