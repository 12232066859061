import Splide from '@splidejs/splide';

const splideOptions = {
  rewind: false,
  speed: 800,
  pagination: false,
};

const largeCarouselInit = () => {
  const carousels = Array.from(
    document.querySelectorAll('[data-module="carousel-feature"]')
  );
  carousels.forEach((item) => {
    const slider = new Splide(item, splideOptions);
    const indexEl = item.querySelector('.js-slide-index');
    slider.mount();

    slider.on('move', () => {
      indexEl.innerHTML = `${slider.index + 1}`;
    });
  });
};

export default largeCarouselInit;
