const navInit = () => {
  const menuButton = document.querySelector('.menu-open');
  const closeButton = document.querySelector('.menu-close');
  const backButton = document.querySelectorAll('.menu-back');
  const mobileMenu = document.querySelector('.mobile-menu');
  const childButton = document.querySelectorAll('.child-menu-button');
  const header = document.querySelector('header');
  const body = document.querySelector('body');
  const stationButton = document.querySelector('.station-button');
  const stationButtonText = stationButton.querySelector('.station-button-text');

  let lastScrollTop = 0; // Variable to store the last scroll position

  window.addEventListener('scroll', function () {
    // Get the header element
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop; // Get the current scroll position

    // If the current scroll position is greater than the last scroll position, the user is scrolling down
    if (scrollTop > lastScrollTop) {
      header.classList.add('-translate-y-100'); // Move the header up out of view (adjust as necessary)
      stationButton.style.width = '56px';
      stationButton.style.borderRadius = '1000px';
      stationButton.style.paddingLeft = '18px';
      stationButtonText.style.opacity = '0';
    } else {
      header.classList.remove('-translate-y-100'); // Move the header back into view
      stationButton.style.width = '194px';
      stationButton.style.borderRadius = '8px';
      stationButton.style.paddingLeft = '24px';
      stationButtonText.style.opacity = '1';
    }

    lastScrollTop = scrollTop; // Update the last scroll position
  });

  menuButton.addEventListener('click', (e) => {
    e.preventDefault();

    mobileMenu.classList.toggle('opacity-0');
    mobileMenu.classList.toggle('invisible');
    body.classList.toggle('overflow-hidden');
  });

  closeButton.addEventListener('click', (e) => {
    e.preventDefault();

    mobileMenu.classList.toggle('opacity-0');
    mobileMenu.classList.toggle('invisible');
    body.classList.toggle('overflow-hidden');
  });

  [...childButton].forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();

      const parent = button.parentElement;
      const child = parent.querySelector('.child-menu');

      child.classList.toggle('opacity-0');
      child.classList.toggle('invisible');
    });
  });

  [...backButton].forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();

      const parent = button.parentElement;

      parent.classList.toggle('opacity-0');
      parent.classList.toggle('invisible');
    });
  });

  // Search buttons
  const searchBtns = document.querySelectorAll('.search-button');

  searchBtns.forEach((searchBtn) => {
    searchBtn.addEventListener('click', (e) => {
      e.preventDefault();

      const prevPath = window.location.pathname;

      sessionStorage['prevpath'] = prevPath;

      const readValue = sessionStorage['prevpath'];

      location.href = '/?s';
    });
  });
};

export default navInit;
