import domReady from '@roots/sage/client/dom-ready';
import navInit from './navigation/index.js';
import testimonialCarouselInit from './carousels/testimonial/index.js';
import largeCarouselInit from './carousels/large/index.js';
import mediumCarouselInit from './carousels/medium/index.js';
import smallCarouselInit from './carousels/small/index.js';
import mobileGridCarouselInit from './carousels/mobile-grid/index.js';
import forecourtExperienceInit from './forecourt-experience/index.js';
import contactFormInit from './contact-form/index.js';
import accordionInit from './accordion/index.js';
import initSearch from './core/search.js';
import initModal from './modal/modal.js';
import stickyInit from './core/sticky.js';
import AOS from 'aos';

/**
 * Application entrypoint
 */
domReady(async () => {
  navInit();
  stickyInit();
  // BLOCKS //
  testimonialCarouselInit();
  largeCarouselInit();
  mediumCarouselInit();
  smallCarouselInit();
  mobileGridCarouselInit();
  accordionInit();
  initSearch();
  initModal();
  forecourtExperienceInit();
  contactFormInit();
  // END BLOCKS //
  AOS.init({
    duration: 500,
    easing: 'cubic-bezier(0.76, 0, 0.24, 1)',
    anchorPlacement: 'center-bottom',
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
