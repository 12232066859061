const forecourtExperienceInit = () => {
  const section = document.querySelector(
    '[data-module="forecourt-experience"]'
  );

  if (section) {
    let buttons = section.querySelectorAll('[data-index]');
    let boxes = section.querySelectorAll('[data-box-index]');

    [...buttons].forEach((button) => {
      button.addEventListener('click', () => {
        let index = button.dataset.index;
        let box = section.querySelector(`[data-box-index="${index}"]`);
        let currentButtons = section.querySelectorAll(
          `[data-index="${index}"]`
        );

        [...boxes].forEach((box) => {
          box.classList.add('opacity-0');
          box.classList.add('invisible');
          box.classList.add('translate-y-50');
        });

        [...buttons].forEach((button) => {
          button.classList.remove('current');
        });

        [...currentButtons].forEach((button) => {
          button.classList.add('current');
        });

        box.classList.remove('opacity-0');
        box.classList.remove('invisible');
        box.classList.remove('translate-y-50');
      });
    });
  }
};

export default forecourtExperienceInit;
