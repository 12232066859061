import Splide from '@splidejs/splide';

const splideOptions = {
  rewind: false,
  gap: '3.2rem',
  perPage: 3,
  speed: 800,
  pagination: false,
  breakpoints: {
    1023: {
      perPage: 2,
      gap: '2rem',
    },
    639: {
      perPage: 1,
    },
  },
};

const smallCarouselInit = () => {
  const carousels = Array.from(
    document.querySelectorAll(
      '[data-module="carousel-small-video"], [data-module="carousel-small-image"], [data-module="carousel-card"], [data-module="carousel-history"]'
    )
  );
  carousels.forEach((item) => {
    const slider = new Splide(item, splideOptions);
    const indexEl = item.querySelector('.js-slide-index');
    slider.mount();

    slider.on('move', () => {
      indexEl.innerHTML = `${slider.index + 1}`;
    });
  });
};

export default smallCarouselInit;
