import Splide from '@splidejs/splide';

const splideOptions = {
  rewind: false,
  gap   : '3.2rem',
  perPage: 3,
  speed: 800,
  breakpoints: {
    1023: {
      perPage: 2,
      gap   : '2rem',
    },
    639: {
      perPage: 1,
    },
  }
}

const mediumCarouselInit = () => {
  const carousels = Array.from(document.querySelectorAll('.medium-carousel'))
  carousels.forEach(item => {
    const slider = new Splide( item, splideOptions );
    slider.mount();
  })
}

export default mediumCarouselInit