// Open modal
const handleOpenModal = (src) => {
  const body = document.querySelector('body');
  body.classList.add('overflow-hidden');

  const modal = document.querySelector('.video-modal .micromodal-slide');

  modal.classList.add('block');
  modal.classList.remove('hidden');

  setTimeout(() => {
    modal.classList.remove('opacity-0');
    modal.classList.add('opacity-1');
  }, 10);

  setTimeout(() => {
    modal.setAttribute('aria-hidden', false);
  }, 500);

  const vid = document.querySelector('.plyr__iframe');
  vid.setAttribute('src', src);
};

const handleOpenFiltersModal = (src) => {
  const body = document.querySelector('body');
  body.classList.add('overflow-hidden');

  const modal = document.querySelector('.filters-modal .micromodal-slide');

  modal.classList.add('block');
  modal.classList.remove('hidden');

  setTimeout(() => {
    modal.classList.remove('opacity-0');
    modal.classList.add('opacity-1');
  }, 10);

  setTimeout(() => {
    modal.setAttribute('aria-hidden', false);
  }, 500);

  const vid = document.querySelector('.plyr__iframe');
  vid.setAttribute('src', src);
};

const handleOpenSortModal = (src) => {
  const body = document.querySelector('body');
  body.classList.add('overflow-hidden');

  const modal = document.querySelector('.sort-modal .micromodal-slide');

  modal.classList.add('block');
  modal.classList.remove('hidden');

  setTimeout(() => {
    modal.classList.remove('opacity-0');
    modal.classList.add('opacity-1');
  }, 10);

  setTimeout(() => {
    modal.setAttribute('aria-hidden', false);
  }, 500);

  const vid = document.querySelector('.plyr__iframe');
  vid.setAttribute('src', src);
};

// Close modal
const handleCloseModal = () => {
  const body = document.querySelector('body');
  body.classList.remove('overflow-hidden');

  const modal = document.querySelector('.video-modal .micromodal-slide');

  modal.classList.remove('opacity-1');
  modal.classList.add('opacity-0');

  setTimeout(() => {
    modal.classList.remove('block');
    modal.classList.add('hidden');
    modal.setAttribute('aria-hidden', true);
  }, 500);
};

const handleCloseFiltersModal = () => {
  const body = document.querySelector('body');
  body.classList.remove('overflow-hidden');

  const modal = document.querySelector('.filters-modal .micromodal-slide');

  modal.classList.remove('opacity-1');
  modal.classList.add('opacity-0');

  setTimeout(() => {
    modal.classList.remove('block');
    modal.classList.add('hidden');
    modal.setAttribute('aria-hidden', true);
  }, 500);
};

const handleCloseSortModal = () => {
  const body = document.querySelector('body');
  body.classList.remove('overflow-hidden');

  const modal = document.querySelector('.sort-modal .micromodal-slide');

  modal.classList.remove('opacity-1');
  modal.classList.add('opacity-0');

  setTimeout(() => {
    modal.classList.remove('block');
    modal.classList.add('hidden');
    modal.setAttribute('aria-hidden', true);
  }, 500);
};

const initModal = () => {
  const videoBtns = document.querySelectorAll('.video-link');

  videoBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      // Replace with e.target.attributes.dataurl.value
      // handleOpenModal('https://www.youtube.com/embed/bTqVqk7FSmY?origin=amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1')
      handleOpenModal(btn.dataset.url);
    });
  });

  const closeModal = document.querySelector('.video-modal .modal__close');
  closeModal.addEventListener('click', () => {
    handleCloseModal();
  });

  const filtersBtn = document.querySelectorAll('.filters-button');

  filtersBtn.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      handleOpenFiltersModal();
    });
  });

  const closeFiltersModal = document.querySelector(
    '.filters-modal .modal__close'
  );
  if (closeFiltersModal) {
    closeFiltersModal.addEventListener('click', () => {
      handleCloseFiltersModal();
    });
  }

  const sortBtn = document.querySelectorAll('.sort-button');

  sortBtn.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      handleOpenSortModal();
    });
  });

  const closeSortModal = document.querySelector('.sort-modal .modal__close');
  if (closeSortModal) {
    closeSortModal.addEventListener('click', () => {
      handleCloseSortModal();
    });
  }
};

export default initModal;
